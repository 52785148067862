import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import { Caption } from '@components/type';
import * as styled from './styles';

function ImageBlock({ credit, image, altText }) {
  if (!get(image, 'asset.fluid')) {
    return null;
  }

  const isVerticalImage = get(image, 'asset.fluid.aspectRatio', 1) < 1;

  return (
    <styled.Wrapper isVerticalImage={isVerticalImage}>
      <styled.Figure>
        <styled.ImageWrapper
          aspectRatio={get(image, 'asset.fluid.aspectRatio', 1)}
          isVerticalImage={isVerticalImage}
        >
          <styled.Image
            fluid={image.asset.fluid}
            imgStyle={{ maxHeight: '100%', objectFit: 'contain' }}
            alt={altText}
          />
          {(credit) && (
            <styled.FigCaption>
              <Caption sizes={['small', 'regular']}>
                {credit && <em>({credit})</em>}
              </Caption>
            </styled.FigCaption>
          )}
        </styled.ImageWrapper>
      </styled.Figure>
    </styled.Wrapper>
  );
}

ImageBlock.propTypes = {
  credit: PropTypes.string,
  image: PropTypes.object.isRequired,
  altText: PropTypes.string,
};

export default ImageBlock;
