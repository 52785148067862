import styled, { css } from 'styled-components';

import colors from '@utils/colors';
import { mq } from '@utils/responsive';
import { H_PADDING_MOBILE } from '@utils/space';

import { Heading } from '@components/type';
import { TextWrapper } from '../block/Text/styles';


export const Inner = styled.div`
  position: relative;
  z-index: 1;
`;

export const Title = styled(Heading)`
  margin-top: 24px;
  margin-bottom: 4px;

  &.active {
    color: ${(props) => getTitleColor(props.colorTheme)};
  }

  &.main {
    margin-bottom: 42px;
  }
`;

export const FAQItems = styled.div`
  margin-top: 24px;
  margin-bottom: 4px;
`;

export const FAQItem = styled.div`
  padding: 0;
`;

export const IconContainer = styled.div`
  position: absolute;
  right: 8px;
  width: 42px;
  height: 42px;
  border-radius: 5px;
  top: calc(50% - 21px);
  display: flex;
  align-items: center;
  justify-content: center;

  &.active {
    background: ${(props) => getBackgroundColor(props.colorTheme)};
  }
`;

export const Icon = styled.div`
  top: calc(50% - 21px);
  height: 28px;
`;

export const FAQItemTitle = styled.div`
  padding: 0 64px 24px 16px;
  border-bottom: 2px solid ${(props) => getBorderColor(props.colorTheme)};
  border-top: 2px solid ${(props) => getBorderColor(props.colorTheme)};
  cursor: pointer;
  margin-bottom: -2px;
  position: relative;
  background: transparent;
  transition: all .7s ease;

  &.active {
    background: ${(props) => getTitleBG(props.colorTheme)};
  }
`;

export const FAQItemDescriptionWrapper = styled.div`
  transition: all .7s ease;
  max-height: 0;
  overflow: hidden;

  &.active {
    max-height: 100vh;
  }
`;

export const FAQItemDescription = styled.div`
  padding: 16px;
`;

export const Wrapper = styled.div`
  position: relative;
  z-index: 1;
  transform: translate(0, 0);

  ${(props) =>
    !props.noPadding &&
    css`
      padding: 0 ${H_PADDING_MOBILE};

      ${mq.gtmd`
      width: ${(5 / 12) * 100}%;
      margin: 0 auto;
      padding: 0;
      `}

      &:not(:last-child) {
        margin-bottom: 24px;
      }
    `}

  ${(props) => props.noPadding && css`
    ${TextWrapper}:last-child {
      margin-bottom: 0;
    }
  `}
`;

function getBorderColor(colorTheme) {
  switch (colorTheme) {
    case 'yellow':
      return colors.Y200;
    case 'green':
      return colors.G200;
    case 'orange':
      return colors.O200;
    case 'purple':
      return colors.P200;
    default:
      return colors.B200;
  }
}

function getBGColor(colorTheme) {
  switch (colorTheme) {
    case 'yellow':
      return colors.Y100;
    case 'green':
      return colors.G100;
    case 'orange':
      return colors.O100;
    case 'purple':
      return colors.P100;
    default:
      return colors.B100;
  }
}

function getTitleColor(colorTheme) {
  switch (colorTheme) {
    case 'yellow':
      return colors.Y500;
    case 'green':
      return colors.G500;
    case 'orange':
      return colors.O500;
    case 'purple':
      return colors.P500;
    default:
      return colors.B500;
  }
}

function getBackgroundColor(colorTheme) {
  switch (colorTheme) {
    case 'yellow':
      return colors.Y150;
    case 'green':
      return colors.G150;
    case 'orange':
      return colors.O150;
    case 'purple':
      return colors.P150;
    default:
      return colors.B150;
  }
}

function getTitleBG(colorTheme) {
  switch (colorTheme) {
    case 'yellow':
      return colors.Y50;
    case 'green':
      return colors.G50;
    case 'orange':
      return colors.O50;
    case 'purple':
      return colors.P50;
    default:
      return colors.B50;
  }
}


export function getThemeStyles(theme) {
  switch (theme) {
    case 'blue':
      return {
        background: colors.B100,
        fill: colors.B200,
        text: colors.B500,
      };
    case 'green':
      return {
        background: colors.G100,
        fill: colors.G200,
        text: colors.G500,
      };
    case 'orange':
      return {
        background: colors.O100,
        fill: colors.O200,
        text: colors.O500,
      };
    case 'purple':
      return {
        background: colors.P100,
        fill: colors.P200,
        text: colors.P500,
      };
    default:
      return {
        background: colors.Y100,
        fill: colors.Y200,
        text: colors.B500,
      };
  }
}
