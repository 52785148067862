import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import getCTAProps from '@utils/cta';
import Button from '@components/Button';
import { RoughOne, RoughThree, Swirl } from '@components/vector';
import * as styled from './styles';

function PageBlock({
  button,
  description,
  heading,
  imagesExtended,
  imagesFlipped,
  outline,
  colorTheme,
}) {
  const themeStyles = styled.getThemeStyles(colorTheme);

  return (
    <styled.Wrapper outline={outline} colorTheme={colorTheme}>
      <RoughThree />
      <styled.Inner flipped={imagesFlipped} outline={outline} colorTheme={colorTheme}>
        <Swirl color={outline ? themeStyles.background : themeStyles.fill} />
        <styled.Images flipped={imagesFlipped}>
          <styled.Sizer />
          <styled.Sizer />
          {get(imagesExtended[0], 'asset.fluid') && (
            <styled.ImageWrapper>
              <styled.Image credit={imagesExtended[0].credit} fluid={imagesExtended[0].asset.fluid} edge={1} altText={imagesExtended[0].altText}/>
              {imagesExtended[0].credit && <styled.Credit imagesFlipped={imagesFlipped}>({imagesExtended[0].credit})</styled.Credit>}
            </styled.ImageWrapper>
          )}
          {get(imagesExtended[1], 'asset.fluid') && (
            <styled.ImageWrapper>
              <styled.Image fluid={imagesExtended[1].asset.fluid} altText={imagesExtended[1].altText}/>
              {imagesExtended[1].credit && <styled.Credit imagesFlipped={imagesFlipped}>({imagesExtended[1].credit})</styled.Credit>}
            </styled.ImageWrapper>
          )}
        </styled.Images>
        <styled.Content>
          <styled.Heading level={100} colorTheme={colorTheme}>{heading}</styled.Heading>
          <styled.Description sizes={['small', 'regular']}>{description}</styled.Description>
          <Button appearance={colorTheme === 'yellow' ? 'blue' : colorTheme} fill={Boolean(outline)} {...getCTAProps(button)}>
            {button.label}
          </Button>
        </styled.Content>
      </styled.Inner>
      <RoughOne />
    </styled.Wrapper>
  );
}

PageBlock.propTypes = {
  button: PropTypes.shape({
    label: PropTypes.string.isRequired,
    link: PropTypes.string,
  }).isRequired,
  description: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  images: PropTypes.arrayOf(PropTypes.object).isRequired,
  imagesFlipped: PropTypes.bool,
  outline: PropTypes.oneOf(['top', 'bottom', 'both']),
  colorTheme: PropTypes.oneOf(['blue', 'green', 'orange', 'purple', 'yellow']),
};

PageBlock.defaultProps = {
  colorTheme: 'yellow',
};

export default PageBlock;
