import React from 'react';
import PropTypes from 'prop-types';
import * as styled from './styles';

import FAQItem from './faqItem';

function FAQ(props) {
  const {
    colorTheme,
    faqItems,
    title,
  } = props;

  return (
    <styled.Wrapper
      colorTheme={colorTheme}
    >
      <styled.Inner
        colorTheme={colorTheme}
      >
        <styled.Title levels={[300, 200]} className='main'>{title}</styled.Title>
        <styled.FAQItems>
          {faqItems.map(item => (
            <FAQItem {...item} colorTheme={colorTheme} key={item._key} />))}
        </styled.FAQItems>
      </styled.Inner>
    </styled.Wrapper>
  );
}

FAQ.propTypes = {
  title: PropTypes.string,
  faqItems: PropTypes.object,
  colorTheme: PropTypes.oneOf(['blue', 'green', 'orange', 'purple', 'yellow']),
};

FAQ.defaultProps = {
  colorTheme: 'blue',
};

export default FAQ;
