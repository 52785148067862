import React, { useState } from 'react';
import * as styled from './styles';
import colors from '@utils/colors';
import { Minus, Plus } from '@components/Icon';
import { Body } from '@components/type';
import PropTypes from 'prop-types';


function FAQItem(props) {
  const { title, description, colorTheme } = props;
  const [active, setActive] = useState(false);
  const expandItem = () => {
    setActive(!active);
  };
  const getIconColor = e => {
    const brightness = e ? '500' : '170';
    switch (colorTheme) {
      case 'yellow':
        return colors[`Y${brightness}`];
      case 'green':
        return colors[`G${brightness}`];
      case 'orange':
        return colors[`O${brightness}`];
      case 'purple':
        return colors[`P${brightness}`];
      default:
        return colors[`B${brightness}`];
    }
  };
  return (
    <styled.FAQItem>
      <styled.FAQItemTitle colorTheme={colorTheme} onClick={expandItem} className={active ? 'active' : ''}>
        <styled.Title colorTheme={colorTheme} levels={[500, 400]}
                      className={active ? 'active' : ''}>{title}</styled.Title>
        <styled.IconContainer colorTheme={colorTheme} className={active ? 'active' : ''}>
          <styled.Icon colorTheme={colorTheme}>
            {active
              ? <Minus size={28} color={getIconColor(true)} />
              : <Plus
                size={28} color={getIconColor()}
                style={{ border: `2px solid ${getIconColor(false)}`, borderRadius: '3px' }}
              />
            }
          </styled.Icon>
        </styled.IconContainer>
      </styled.FAQItemTitle>
      <styled.FAQItemDescriptionWrapper className={active ? 'active' : ''} colorTheme={colorTheme}>
        <styled.FAQItemDescription colorTheme={colorTheme}>
          <Body sizes={['small', 'regular']} colorTheme={colorTheme}>{description}</Body>
        </styled.FAQItemDescription>
      </styled.FAQItemDescriptionWrapper>
    </styled.FAQItem>
  );
}

FAQItem.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  colorTheme: PropTypes.oneOf(['blue', 'green', 'orange', 'purple', 'yellow']),
};

export default FAQItem;
