import styled, { css } from 'styled-components';

import colors from '@utils/colors';
import { mq } from '@utils/responsive';
import { H_PADDING_MOBILE } from '@utils/space';
import { fontStyles, Heading as UnstyledHeading } from '@components/type';

export const Em = styled.span`
  font-style: italic;
`;

export const Hash = styled.a`
  color: ${colors.P200};
  margin-left: -36px;
  margin-right: 12px;
  font-weight: 300;
  opacity: 0;
`;

export const Heading = styled(UnstyledHeading)`
  padding-top: 96px;
  margin-top: -96px;

  &:hover ${Hash} {
    opacity: 1;
  }
`;

export const SidebarWrapper = styled.div`
  padding-bottom: 16px;

  ${mq.gtmd`
    width: ${(4 / 12) * 100}%;
    min-width: 300px;
    padding-bottom: 32px;

    ${(props) =>
      props.align === 'right'
        ? css`
            float: right;
            margin-right: -${(2 / 12) * 100}%;
            margin-left: 32px;
          `
        : css`
            float: left;
            margin-right: 32px;
            margin-left: -${(2 / 12) * 100}%;
          `}
  `}
`;

export const TextWrapper = styled.div`
  margin-bottom: ${(props) => (props.type === 'normal' ? '24px' : '32px')};

  & p {
    ${fontStyles.normal};
  }

  & h1 {
    ${fontStyles.h1};
  }

  & h2 {
    ${fontStyles.h2};
    padding-top: 24px;
  }

  & h3 {
    ${fontStyles.h3};
  }

  & h4 {
    ${fontStyles.h4};
  }

  & blockquote {
    ${fontStyles.blockquote};
  }

  & em {
    ${fontStyles.em};
  }

  & strong {
    ${fontStyles.strong};
  }

  & ul {
    ${fontStyles.normal};
    padding-left: 40px;
    list-style-type: disc;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  z-index: 1;
  transform: translate(0, 0);

  ${(props) =>
    !props.noPadding &&
    css`
      padding: 0 ${H_PADDING_MOBILE};

      ${mq.gtmd`
      width: ${(5 / 12) * 100}%;
      margin: 0 auto;
      padding: 0;
      `}

      &:not(:last-child) {
        margin-bottom: 24px;
      }
    `}

  ${(props) => props.noPadding && css`
    ${TextWrapper}:last-child {
      margin-bottom: 0;
    }
  `}
`;
