import React from 'react';
import BlockContent from '@sanity/block-content-to-react';

import Annotation from '@components/Annotation';

const serializers = {
  types: {
    block: (props) => {
      const {style = 'normal'} = props.node;

      if (/^h\d/.test(style)) {
        const nameKey = props.children.length &&
                        typeof props.children[0].replace !== 'undefined' ?
                          props.children[0].replace(/\W/g, '-').toLowerCase() : '';
        const hashUrl = typeof window !== 'undefined' ?
          `${window.location.origin}${window.location.pathname}#${nameKey}` : '';
        const headerProps = {
          id: nameKey,
          onClick: () => navigator.clipboard.writeText(hashUrl)
        }

        return React.createElement(style, headerProps, props.children);
      }

      return BlockContent.defaultSerializers.types.block(props)
    }
  },
  marks: {
    annotation: (props) => {
      return (
        <Annotation annotation={props.mark.text}>{props.children}</Annotation>
      );
    },
    link: ({ mark, children }) => {
      const { blank, href } = mark;
      return blank ? (
        <a href={href} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      ) : (
        <a href={href}>{children}</a>
      );
    },
  },
};

function Block({ content }) {
  return <BlockContent blocks={content} serializers={serializers} />;
}

export default Block;
